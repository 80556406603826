import React from 'react';
import { Link } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';

import { localizePath } from '../../utils/localize';
import CustomLink from '../CustomLink';
import useCyclesData from '../../queries/cyclesQueries';
import useStudiesData from '../../queries/studiesQueries';

import './studies.scss';

const StudiesDetailPage = (props, pageContext) => {
  const cycles = useCyclesData();
  const studies = useStudiesData();
  const langStudiesData = studies.filter(
    (item) => item.locale === props.data.locale,
  );
  const langCyclesData = cycles.filter(
    (item) => item.locale === props.data.locale,
  );

  return (
    <div>
      <div className="main-wrapper col-lg-7 col-12 d-lg-flex mx-auto">
        <div className="left-container-wrapper col-lg-4 col-12 py-5">
          <div className="accordion accordion-flush" id="accordionFlushExample">
            {langCyclesData.map((cycle) => (
              <div className="bg-transparent accordion-item" key={cycle.id}>
                <h2 className="accordion-header" id={cycle.headingDropdownId}>
                  <button
                    className="shadow-none accordion-button bg-transparent collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#${cycle.accordionId}`}
                    aria-expanded="false"
                    aria-controls="flush-collapse"
                  >
                    <Link to={cycle.slug}>{cycle.title}</Link>
                  </button>
                </h2>
                <div
                  id={cycle.accordionId}
                  className="accordion-collapse collapse"
                  aria-labelledby={cycle.accordionId}
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <ul className="group-links">
                      {langStudiesData
                        .filter((item) => item.studiesId === cycle.cycleId)
                        .map((item) => (
                          <li className="links pt-1" key={item.id}>
                            <CustomLink
                              className="language-text"
                              link={{
                                url: `${localizePath({
                                  ...item,
                                  ...pageContext,
                                  slug: item.slug,
                                })}`,
                              }}
                              to={`/${item.slug}`}
                            >
                              <p className="link">{item.title}</p>
                            </CustomLink>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="right-container-wrapper py-5 ms-lg-3 ms-xl-0 ms-xxl-1 pe-lg-4 col-lg-8 col-xl-8 col-xxl-8 col-12">
          <div className="title-container mb-2 ">
            <h4 className="title-text col-lg-11 ms-lg-4 me-lg-0 ms-2 me-2">
              {props.data.description}
            </h4>
          </div>
          <div className="content-wrapper ms-lg-0 me-lg-0 ms-2 me-2">
            <ReactMarkdown className="content-text">
              {props.data.content}
            </ReactMarkdown>
          </div>
        </div>
      </div>
    </div>
  );
};

StudiesDetailPage.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    studiesId: PropTypes.string.isRequired,
    cycle: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};

export default StudiesDetailPage;
