import { graphql, useStaticQuery } from 'gatsby';

const useStudiesData = () => {
  const {
    allStrapiStudies: { nodes: studies },
  } = useStaticQuery(graphql`
    {
      allStrapiStudies(sort: { order: ASC, fields: strapiId }) {
        nodes {
          studiesId
          content
          cycle {
            id
            locale
          }
          description
          id
          locale
          localizations {
            id
            locale
          }
          slug
          title
        }
      }
    }
  `);
  return studies;
};

export default useStudiesData;
