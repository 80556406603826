import { graphql, useStaticQuery } from 'gatsby';

const useCyclesData = () => {
  const {
    allStrapiCycles: { nodes: cycles },
  } = useStaticQuery(graphql`
    {
      allStrapiCycles (sort: {fields: strapiId, order: ASC}) {
        nodes {
          cycleId
          accordionId
          headingDropdown
          id
          locale
          localizations {
            id
            locale
          }
          studies {
            cycle
            description
            id
            locale
            slug
            title
            content
          }
          title
        }
      }
    }
  `);
  return cycles;
};

export default useCyclesData;
